/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
/* eslint-disable */
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, CheckIcon } from '@heroicons/react/24/outline';
import Analytics from '../../../utils/analytics';
import { addToken, editToken } from '../../../redux/slices/tokens/tokens';
import Spinner from '../../../components/shared/Spinner';
import { selectOrganisation } from '../../../redux/slices/organisation';
import { EntityContainer } from '../../../redux/slices/core.models';
import { ApiToken, ApiTokenPermission } from '../../../redux/slices/tokens/token.models';

interface Props {
  open: boolean;
  selected: ApiToken | null;
  onUpdateToken: (token: ApiToken) => void;
  onAddToken: (token: ApiToken) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AddTokenModal({ open, selected, onUpdateToken, onAddToken, setOpen }: Props) {
  const org = useSelector(selectOrganisation);
  const isEditing = selected !== null;
  const defaultName = selected?.data.name || '';
  const selectedPermissions = selected?.data.permissions || [];
  const defaultPermissions = selectedPermissions.reduce((store, perm) => {
    return {
      ...store,
      [perm.resource_id]: perm.permission_type,
    };
  }, {});
  const [name, setName] = useState(defaultName);
  const [permissions, setPermissions] = useState<EntityContainer<string>>(defaultPermissions);
  const [isSaving, setIsSaving] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [savedToken, setSavedToken] = useState<ApiToken | null>(null);
  const isSaved = saveSuccess && savedToken !== null;
  const token = savedToken?.data.api_key || '';
  const apps = org?.data.apps || [];
  const appData =
    [...apps].sort((a, b) => {
      const aName = a.data.name || '';
      const bName = b.data.name || '';
      return aName.localeCompare(bName);
    }) || [];
  const handleClose = () => {
    if (!isEditing && savedToken) onAddToken(savedToken);
    setOpen(false);
  };
  const handleItem = (resource: string, permission: string) => {
    if (permission === '') {
      const updates = {
        ...permissions,
      };
      delete updates[resource];
      setPermissions(updates);
    } else {
      setPermissions({
        ...permissions,
        [resource]: permission,
      });
    }
  };
  const copyToClipboard = () => {
    navigator.clipboard.writeText(token)
      .then(() => {
        // Optional: Add some feedback to the user here, like changing the button text to "Copied!" for a few seconds.
        setIsCopied(true);
      })
      .catch(err => {
        console.error('Error in copying text: ', err);
      });
  };
  const handleCreate = async () => {
    const permissionData: ApiTokenPermission[] = Object.keys(permissions).map((resource) => {
      return {
        'resource_id': resource,
        'permission_type': permissions[resource],
      }
    });
    try {
      setIsSaving(true);
      const response = await addToken(name, permissionData);
      setSaveSuccess(true);
      setSavedToken(response.data.data);
    } catch (error) {
      Analytics.capture(error);
    } finally {
      setIsSaving(false);
    }
  };
  const handleEdit = async () => {
    if (!selected) return;
    const permissionData: ApiTokenPermission[] = Object.keys(permissions).map((resource) => {
      return {
        'resource_id': resource,
        'permission_type': permissions[resource],
      }
    });
    try {
      setIsSaving(true);
      const updates: ApiToken = {
        ...selected,
        data: {
          ...selected.data,
          name,
          permissions: permissionData,
        },
      };
      const response = await editToken(updates);
      if (response.status === 200) {
        onUpdateToken(response.data.data);
        setOpen(false);
      }
    } catch (error) {
      Analytics.capture(error);
    } finally {
      setIsSaving(false);
    }
  };
  const handleSave = () => {
    if (isEditing) {
      handleEdit();
    } else {
      handleCreate();
    }
  };
  const btnColour = isCopied ? 'bg-green-500' : 'bg-blue-500 hover:bg-blue-700';
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={handleClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Add API Token
                    </Dialog.Title>
                    <div className="mt-2 w-full">
                      <div className="w-full">
                        <label htmlFor="api-key-name" className="block text-sm font-medium leading-6 text-gray-900">
                          Name
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="api-key-name"
                            id="api-key-name"
                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            placeholder="Api Key Name"
                            disabled={isSaved}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      {isSaved && (
                        <div className="w-full mt-4">
                          <label htmlFor="api-key-name" className="block text-sm font-medium leading-6 text-gray-900">
                            Token
                          </label>
                          <div className="mt-2 w-full flex items-center space-x-2">
                            <input
                              type="text"
                              name="api-key-name"
                              id="api-key-name"
                              className="bg-gray-200 text-gray-700 border border-gray-300 rounded-md p-2 text-sm w-full"
                              disabled
                              value={token}
                              onChange={(e) => setName(e.target.value)}
                            />
                            <button onClick={copyToClipboard} className={`${btnColour} text-white font-bold text-xs py-2 px-4 rounded`}>
                              {!isCopied && 'Copy'}
                              {isCopied && <CheckIcon className="h-4 w-4" />}
                            </button>
                          </div>
                        </div>
                      )}
                      {appData.map((app) => {
                        const appName = app.data.name || '';
                        const collections = app.data.collections || [];
                        return (
                          <div>
                            <h4 className="font-medium mt-12 mb-6 w-full">{appName}</h4>
                            {collections.map((collection) => {
                              const collectionName = collection.name || '';
                              const resourceId = collection.resource_id || '';
                              const value = permissions[resourceId] || '';
                              const isSelected = value !== '';
                              const textColour = isSelected ? 'text-blue-600': 'text-gray-900';
                              const inputColour = isSelected ? 'text-blue-600 ring-blue-200 bg-blue-50': 'text-gray-900 ring-gray-300 bg-white';
                              return (
                                <div className="mt-2 flex items-center justify-between border-b border-gray-200 pb-4">
                                  <label
                                    htmlFor="api-key-collection-name"
                                    className={`block text-sm font-medium leading-6 ${textColour}`}
                                  >
                                    {collectionName}
                                  </label>
                                  <div className="">
                                    <select
                                      name={collectionName}
                                      value={value}
                                      disabled={isSaved}
                                      onChange={(e) => handleItem(resourceId, e.target.value)}
                                      className={`mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ${inputColour} ring-1 ring-inset text-sm sm:leading-6`}
                                    >
                                      <option value="">None</option>
                                      <option value="read">Read</option>
                                      <option value="write">Write</option>
                                    </select>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                      <button
                        onClick={handleSave}
                        type="button"
                        className="mt-6 rounded bg-blue-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                      >
                        {isSaving ? <Spinner size="small" /> : 'Save'}
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
