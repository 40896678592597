/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-lonely-if */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-cycle */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { getEntityById, resetSelectedEntityState, updateRelEntity } from '../../redux/slices/database';
import useAppDispatch from '../../hooks/useAppDispatch';
import { Collection } from '../../redux/models/database.model';
import EditEntityModal from './EditEntityModal';
import { getCollection } from '../../redux/slices/collections';
import Analytics from '../../utils/analytics';
import { Entity } from '../../redux/models/auth.models';

const loadEntity = (collection: Collection, entityId: string) => {
  const baseUrl = collection.data.app?.data.base_url || '';
  const path = collection.data.path || '';
  const url = `${baseUrl}/${path}/${entityId}`;
  return getEntityById(url);
};

interface Props {
  entityId: string;
  collectionId: string;
  isOpen: boolean;
  isRel?: boolean;
  onClose: () => void;
  onUpdate?: (entity: Entity) => void;
}

// Add selected entity to redux
function EditEntityContainer({ entityId, collectionId, isOpen, isRel, onUpdate, onClose }: Props) {
  const dispatch = useAppDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [collection, setCollection] = useState<Collection | null>(null);
  const [entity, setEntity] = useState<Entity | null>(null);
  const handleUpdate = (updates: Entity) => {
    setEntity(updates);
    if (onUpdate) onUpdate(updates);
  };
  const handleReload = async (item: Entity) => {
    if (!collection) return;
    setIsLoading(true);
    const entityResponse = await loadEntity(collection, item.entity_id);
    if (entityResponse.status === 200) {
      const entityData = entityResponse.data.data;
      setEntity(entityData);
    }
    setIsLoading(false);
  };
  const handleClose = () => {
    if (isRel && entity && collection) dispatch(updateRelEntity({ entity, collectionId: collection.entity_id }));
    onClose();
  };
  console.log('remove edit on save');
  useEffect(() => {
    const load = async (_entityId: string, _collectionId: string) => {
      try {
        setIsLoading(true);
        setIsLoaded(false);
        const colResponse = await getCollection(_collectionId);
        if (colResponse.status === 200) {
          const colData: Collection = colResponse.data.data;
          const entityResponse = await loadEntity(colData, _entityId);
          if (entityResponse.status === 200) {
            const entityData = entityResponse.data.data;
            setCollection(colData);
            setEntity(entityData);
          }
        }
      } catch (error) {
        Analytics.capture(error);
      } finally {
        setIsLoading(false);
        setIsLoaded(true);
      }
    };
    if (!isLoaded && !isLoading) load(entityId, collectionId);
  }, [entity, entityId, collectionId, isLoaded, isLoading]);

  useEffect(() => {
    return () => {
      dispatch(resetSelectedEntityState(null));
    };
  }, [dispatch]);
  return (
    <EditEntityModal
      isLoading={isLoading}
      entity={entity}
      collection={collection}
      isOpen={isOpen}
      isRel={isRel || false}
      onReload={handleReload}
      handleUpdate={handleUpdate}
      onClose={handleClose}
    />
  );
}

EditEntityContainer.defaultProps = {
  onUpdate: undefined,
  isRel: false,
};

export default EditEntityContainer;
