import React from 'react';
import { IntegrationStatus } from '../../../redux/slices/integration.models';

const capitaliseFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const getStatus = (status: IntegrationStatus) => {
  if (status === IntegrationStatus.Waiting) {
    return 'Waiting';
  }
  if (status === IntegrationStatus.None || status === IntegrationStatus.InActive) {
    return 'In Active';
  }
  return capitaliseFirstLetter(status);
};

const getBadgeColour = (status: IntegrationStatus) => {
  if (status === IntegrationStatus.Error) {
    return 'bg-red-100 text-red-800';
  }
  if (status === IntegrationStatus.Waiting) {
    return 'bg-yellow-100 text-yellow-800';
  }
  if (status === IntegrationStatus.None || status === IntegrationStatus.InActive) {
    return 'bg-gray-100 text-gray-800';
  }
  return 'bg-green-100 text-green-800';
};

interface Props {
  status: IntegrationStatus;
}

export default function IntegrationStatusBadge({ status }: Props) {
  return (
    <span className={`rounded-full px-2 py-1 text-xs font-medium ${getBadgeColour(status)}`}>{getStatus(status)}</span>
  );
}
