/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import PageHeader from '../../components/headers/PageHeader';
import ApiTokenList from './components/ApiTokenList';
import AddTokenModal from './components/AddTokenModal';
import Analytics from '../../utils/analytics';
import { loadTokens } from '../../redux/slices/tokens/tokens';
import { ApiToken } from '../../redux/slices/tokens/token.models';
import Spinner from '../../components/shared/Spinner';

export default function ApiTokenContainer() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tokens, setTokens] = useState<ApiToken[]>([]);
  const [selectedToken, setSelectedToken] = useState<ApiToken | null>(null);
  const handleEdit = async (token: ApiToken) => {
    await setSelectedToken(token);
    setShowModal(true);
  };
  const handleDelete = async (token: ApiToken) => {
    setTokens((prev) => {
      return prev.filter((item) => item.entity_id !== token.entity_id);
    });
  };
  const handleOnAdd = (token: ApiToken) => {
    setTokens((prev) => [...prev, token]);
  };
  const handleOnEdit = (token: ApiToken) => {
    setTokens((prev) => {
      return prev.map((item) => {
        if (item.entity_id === token.entity_id) {
          return token;
        }
        return item;
      });
    });
  };
  const handleNewKey = () => {
    setShowModal(true);
  };
  useEffect(() => {
    const load = async () => {
      try {
        setIsLoading(true);
        const response = await loadTokens();
        if (response.status === 200) {
          const tokenData: ApiToken[] = response.data.data.items || [];
          setTokens(tokenData);
        }
      } catch (error) {
        Analytics.capture(error);
      } finally {
        setIsLoading(false);
      }
    };
    load();
  }, []);
  return (
    <div className="min-h-full relative">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex flex-col lg:pl-64">
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="flex-1">
          <PageHeader title="Developers" button buttonTitle="Add" handleAction={handleNewKey} />
          {isLoading && (
            <div className="flex pt-32 w-full h-full items-center justify-center">
              <Spinner size="small" />
            </div>
          )}
          {!isLoading && <ApiTokenList tokens={tokens} handleEdit={handleEdit} handleDelete={handleDelete} />}
          {!isLoading && showModal && (
            <AddTokenModal
              open={showModal}
              selected={selectedToken}
              setOpen={setShowModal}
              onAddToken={handleOnAdd}
              onUpdateToken={handleOnEdit}
            />
          )}
        </main>
      </div>
    </div>
  );
}
