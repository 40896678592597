import React from 'react';
import Dashboard from './pages/Dashboard';
import AuthGuard from './components/guards/AuthGuard';
import Landing from './pages/landing';
import LandingLayout from './layouts/Landing';
import Page404 from './pages/auth/Page404';
import AutomationList from './pages/automations';
import OrganisationList from './pages/organisations';
import IntegrationsList from './pages/integrations';
import AutomationDetails from './pages/automations/AutomationDetails';
import UsersList from './pages/users';
import CollectionList from './pages/collections';
import CollectionDetails from './pages/collections/CollectionDetails';
import EntityDetails from './pages/entities/EntityDetails';
import Reports from './pages/reports';
import ApiTokenContainer from './pages/api-tokens/ApiTokenContainer';

const routes = [
  {
    path: '/',
    element: (
      <LandingLayout>
        <Landing />
      </LandingLayout>
    ),
  },
  {
    path: '/:orgCode/collections/:collectionPath',
    element: (
      <AuthGuard>
        <Dashboard />
      </AuthGuard>
    ),
  },
  {
    path: '/:orgCode/collections/:collectionId/:entityId',
    element: (
      <AuthGuard>
        <EntityDetails />
      </AuthGuard>
    ),
  },
  {
    path: '/:orgCode/collections',
    element: (
      <AuthGuard>
        <CollectionList />
      </AuthGuard>
    ),
  },
  {
    path: '/:orgCode/collections/edit/:collectionId',
    element: (
      <AuthGuard>
        <CollectionDetails />
      </AuthGuard>
    ),
  },
  {
    path: '/:orgCode/automations',
    element: (
      <AuthGuard>
        <AutomationList />
      </AuthGuard>
    ),
  },
  {
    path: '/:orgCode/automations/:id',
    element: (
      <AuthGuard>
        <AutomationDetails />
      </AuthGuard>
    ),
  },
  {
    path: '/organisations',
    element: (
      <AuthGuard>
        <OrganisationList />
      </AuthGuard>
    ),
  },
  {
    path: '/:orgCode/integrations',
    element: (
      <AuthGuard>
        <IntegrationsList />
      </AuthGuard>
    ),
  },
  {
    path: '/:orgCode/dashboard',
    element: (
      <AuthGuard>
        <Reports />
      </AuthGuard>
    ),
  },
  {
    path: '/:orgCode/users',
    element: (
      <AuthGuard>
        <UsersList />
      </AuthGuard>
    ),
  },
  {
    path: '/:orgCode/developers',
    element: (
      <AuthGuard>
        <ApiTokenContainer />
      </AuthGuard>
    ),
  },
  {
    path: '*',
    element: <Page404 />,
  },
];

export default routes;
