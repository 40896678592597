/* eslint-disable no-console */
import React, { useState } from 'react';
import { Integration, IntegrationStatus } from '../../../redux/slices/integration.models';
import { App, AuthenticationTypes } from '../../../redux/models/app.models';
import Analytics from '../../../utils/analytics';
import { getOAuth2Url } from '../../../redux/slices/integrations';
import Spinner from '../../../components/shared/Spinner';
import useAuth from '../../../hooks/useAuth';
import DateService from '../../../utils/dateService';
import IntegrationStatusBadge from './IntegrationStatusBadge';

/**
 * To Do:
 * - Get latest itegration by auth type
 * - Button state
 * - State label
 */
const getBtnTitle = (connected: boolean) => (connected ? 'Connected' : 'Connect');
const getBtnColour = (connected: boolean) => {
  return connected ? 'bg-green-500 hover:bg-green-800' : 'bg-blue-500 hover:bg-blue-800';
};

const getAuthIntegrations = (app: App, integrations: Integration[], userId: string) => {
  const types = app.data.authentication_types || [];
  if (types.includes(AuthenticationTypes.OAuth2)) {
    const userIntegrations = integrations.filter((intes) => userId !== '' && intes.author === userId);
    return userIntegrations;
  }
  return integrations;
};

const getCurrentIntegration = (integrations: Integration[]) => {
  if (integrations.length < 1) return null;
  if (integrations.length === 1) return integrations[0];
  const sortedInts = [...integrations].sort((a, b) => {
    const aTime = a.modified_at || a.created_at;
    const bTime = b.modified_at || b.created_at;
    if (!aTime) return 1;
    if (!bTime) return -1;
    const aDate = DateService.getUnixTime(aTime);
    const bDate = DateService.getUnixTime(bTime);
    return bDate - aDate;
  });
  return sortedInts[0];
};

interface Props {
  app: App;
  integrations: Integration[];
}

export default function ListItem({ app, integrations }: Props) {
  // const { entity_id: id, data } = integration;
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const userId = user?.id || '';
  const userIntegrations = getAuthIntegrations(app, integrations, userId);
  const integration = getCurrentIntegration(userIntegrations);
  const isConnected = integration !== null;
  const appId = app?.entity_id || '';
  const title = app?.data?.name || '';
  const imgUrl = app?.data?.logo_url || '';
  const details = integration?.data?.details || '';
  const status = integration?.data?.status || IntegrationStatus.None;
  const authTypes = app?.data.authentication_types || [];
  const isOAuth2 = authTypes.includes(AuthenticationTypes.OAuth2);
  console.log(`\n\napp: ${app?.data.name || ''}`);
  console.log(`user id: ${userId}`);
  console.log('user integrations');
  console.log(userIntegrations);
  console.log('last modified');
  console.log(integration);
  const handleAuth = async () => {
    if (!appId) return;
    console.log('authenticate');
    try {
      setIsLoading(true);
      const urlResponse = await getOAuth2Url(appId);
      if (urlResponse.status === 200) {
        const redirectUrl = urlResponse.data.data || '';
        if (redirectUrl !== '') window.open(redirectUrl);
      }
    } catch (error) {
      Analytics.capture(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <li className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg text-center bg-white shadow rounded-r-md border border-gray-200">
      <div className="flex flex-1 flex-col p-8">
        <img className="mx-auto h-32 w-32 flex-shrink-0 object-scale-down rounded-full" src={imgUrl} alt="" />
        <h3 className="mt-6 text-sm font-medium text-gray-900">{title}</h3>
        <dl className="mt-1 flex flex-grow flex-col justify-between">
          <dt className="sr-only">Title</dt>
          <dd className="text-sm text-gray-500">{details}</dd>
          <dt className="sr-only">Status</dt>
          <dd className="mt-3">
            <IntegrationStatusBadge status={status} />
          </dd>
          {isOAuth2 && (
            <dd>
              <button
                className={`inline-flex mt-4 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-100 shadow-sm ${getBtnColour(
                  isConnected
                )}`}
                type="button"
                onClick={handleAuth}
              >
                {isLoading && <Spinner size="small" />}
                {!isLoading && getBtnTitle(isConnected)}
              </button>
            </dd>
          )}
        </dl>
      </div>
    </li>
  );
}
