/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ListItem from './ListItem';
import { Integration } from '../../../redux/slices/integration.models';
import { App } from '../../../redux/models/app.models';
import { EntityContainer } from '../../../redux/slices/core.models';

interface Props {
  apps: App[];
  integrations: Integration[];
}

export default function IntegrationList({ apps, integrations }: Props) {
  const defaultStore: EntityContainer<Integration[]> = {};
  const integrationStore = integrations.reduce((store, integration) => {
    const appId = integration.data?.app?.entity_id || '';
    if (appId === '') return store;
    const ints = store[appId] || [];
    return {
      ...store,
      [appId]: ints.length > 0 ? [...ints, integration] : [integration],
    };
  }, defaultStore);
  return (
    <div className="overflow-hidden pt-12 sm:rounded-md">
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {apps.map((app) => {
          const appIntegrations = integrationStore[app.entity_id] || [];
          return <ListItem key={app.entity_id} app={app} integrations={appIntegrations} />;
        })}
      </ul>
    </div>
  );
}
