import { AppData } from '../redux/models/app.models';
import { axiosInstance } from '../utils/axios';
import ApiTokenApiProvider from './ApiTokenProvider';
import IntegrationApiProvider from './IntegrationApiProvider';
import BaseEntityApiProvider from './base/BaseEntityApiProvider';

class Api {
  static apps = new BaseEntityApiProvider<AppData>('app', axiosInstance);

  static integrations = new IntegrationApiProvider();

  static tokens = new ApiTokenApiProvider();
}

export default Api;
