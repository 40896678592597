/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Organisation } from '../../../redux/models/database.model';

interface Props {
  organisation: Organisation;
  onSelect: (org: Organisation) => void;
}

export default function ListItem({ organisation, onSelect }: Props) {
  const data = organisation?.data;
  const title = data?.name || '';
  const logoUrl = data?.logo_url || '';
  return (
    <li>
      <button
        data-cy={`${title}-org-list-item`}
        type="button"
        className="block hover:bg-gray-50 w-full"
        onClick={() => onSelect(organisation)}
      >
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="flex min-w-0 flex-1 items-center">
            <div className="flex-shrink-0 w-16 h-16">
              <img className="w-full h-16 w-16 object-cover rounded-full" src={logoUrl} alt={`${title} Logo`} />
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <p className="truncate text-xl font-bold text-gray-900 text-left">{title}</p>
              </div>
            </div>
          </div>
          <div>
            <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        </div>
      </button>
    </li>
  );
}
