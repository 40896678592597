import React from 'react';
import { ApiToken } from '../../../redux/slices/tokens/token.models';
import ApiTokenListItem from './ApiTokenListItem';

const DETAILS =
  'API tokens let you integrate applications with the Hopper API. Use of any Non-Hopper Products is subject to the Hopper Terms of Service.';

interface Props {
  tokens: ApiToken[];
  handleEdit: (token: ApiToken) => void;
  handleDelete: (token: ApiToken) => void;
}

export default function ApiTokenList({ tokens, handleEdit, handleDelete }: Props) {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="mt-4 text-base font-semibold leading-6 text-gray-900">API Tokens</h1>
          <p className="mt-2 text-sm text-gray-700">{DETAILS}</p>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Access Token
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Scope
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Version
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {tokens.map((token) => (
                    <ApiTokenListItem
                      token={token}
                      key={token.entity_id}
                      handleEdit={handleEdit}
                      handleDelete={handleDelete}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
