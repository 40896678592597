import { App } from '../models/app.models';
import { DataModel } from './core.models';
import { Organisation } from '../models/database.model';

export enum IntegrationStatus {
  Active = 'active',
  Error = 'error',
  InActive = 'inactive',
  Waiting = 'waiting_oauth2',
  None = '',
}

export interface IntegrationData {
  app: App;
  details?: string;
  organisation: Organisation;
  status?: IntegrationStatus;
}

export type Integration = DataModel<IntegrationData>;
