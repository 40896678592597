import { AxiosInstance } from 'axios';
import BaseEntityEndpointProvider from './BaseEntityEndpointProvider';
import { DataModel } from '../../redux/slices/core.models';
import { CollectionRequest, DEFAULT_REQUEST_BODY, getPostSearchBody } from '../../redux/slices/collection.models';

class BaseEntityApiProvider<T> {
  private endpointProvider: BaseEntityEndpointProvider;

  private httpProvider: AxiosInstance;

  constructor(entity: string, axiosInstance: AxiosInstance) {
    this.endpointProvider = new BaseEntityEndpointProvider(entity);
    this.httpProvider = axiosInstance;
  }

  get api() {
    return this.httpProvider;
  }

  get endpoint() {
    return this.endpointProvider;
  }

  getAll() {
    return this.api.get(this.endpoint.entityPath());
  }

  create(entityData: T) {
    return this.api.post(this.endpoint.entityPath(), entityData);
  }

  update(entity: DataModel<T>) {
    return this.api.put(this.endpoint.entityById(entity.entity_id), entity);
  }

  getById(entityId: string) {
    return this.api.get(this.endpoint.entityById(entityId));
  }

  delete(entity: DataModel<T>) {
    return this.api.delete(this.endpoint.entityById(entity.entity_id));
  }

  find(filters: CollectionRequest = DEFAULT_REQUEST_BODY) {
    return this.api.post(this.endpoint.find(), filters);
  }

  search(field: string, value: string) {
    const filters = getPostSearchBody(field, value);
    return this.api.post(this.endpoint.find(), filters);
  }

  deleteMany(entities: DataModel<T>[]) {
    const ids = entities.map((entity) => entity.entity_id);
    return this.api.post(this.endpoint.find(), ids);
  }
}

export default BaseEntityApiProvider;
