import React, { Fragment, useState } from 'react';
import { Transition, Menu } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import { ApiToken } from '../../../redux/slices/tokens/token.models';
import DateService from '../../../utils/dateService';
import classNames from '../../../utils/tailwind';
import { deleteToken } from '../../../redux/slices/tokens/tokens';
import Analytics from '../../../utils/analytics';
import Spinner from '../../../components/shared/Spinner';

interface Props {
  token: ApiToken;
  handleEdit: (token: ApiToken) => void;
  handleDelete: (token: ApiToken) => void;
}

export default function ApiTokenListItem({ token, handleEdit, handleDelete }: Props) {
  const [isDeleting, setIsDeleting] = useState(false);
  const name = token.data.name || '';
  const permissions = token.data.permissions || [];
  const reads = permissions.filter((permission) => permission.permission_type === 'read');
  const writes = permissions.filter((permission) => permission.permission_type === 'write');
  const scopes = `Read: ${reads.length}, Write: ${writes.length}`;
  const version = token.created_at || token.modified_at || '';
  const date = DateService.getYYYYMMDDFormat(version);
  const key = token.data.api_key || '';
  const apiKey = `••••••••••••${key.slice(-4)}`;
  const onDelete = async () => {
    try {
      setIsDeleting(true);
      const response = await deleteToken(token);
      if (response.status === 200) {
        handleDelete(token);
      }
    } catch (error) {
      Analytics.capture(error);
    } finally {
      setIsDeleting(false);
    }
  };
  return (
    <tr>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{name}</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{apiKey}</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{scopes}</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{date}</td>
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        {isDeleting && <Spinner size="small" />}
        {!isDeleting && (
          <Menu as="div" className="relative ml-auto">
            <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
              <span className="sr-only">Open options</span>
              <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-8 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type="button"
                      onClick={() => handleEdit(token)}
                      className={classNames(
                        active ? 'bg-gray-50' : '',
                        'block px-3 py-1 text-sm leading-6 text-gray-900'
                      )}
                    >
                      Edit<span className="sr-only">, {name}</span>
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type="button"
                      onClick={onDelete}
                      className={classNames(
                        active ? 'bg-gray-50' : '',
                        'block px-3 py-1 text-sm leading-6 text-red-500'
                      )}
                    >
                      Delete<span className="sr-only">, {name}</span>
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        )}
      </td>
    </tr>
  );
}
