import Api from '../../../services/Api';
import { ApiToken, ApiTokenPermission } from './token.models';

export const loadTokens = () => {
  return Api.tokens.find();
};

export const addToken = (name: string, permissions: ApiTokenPermission[]) => {
  return Api.tokens.create({ name, permissions });
};

export const editToken = (token: ApiToken) => {
  return Api.tokens.update(token);
};

export const deleteToken = (token: ApiToken) => {
  return Api.tokens.delete(token);
};
