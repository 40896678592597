class BaseEntityEndpointProvider {
  private entity: string;

  constructor(entity: string) {
    this.entity = entity;
  }

  entityPath() {
    return `/${this.entity}`;
  }

  entityById(entityId: string) {
    return `/${this.entity}/${entityId}`;
  }

  find() {
    return `/${this.entity}/find`;
  }

  deleteMany() {
    return `/${this.entity}/delete`;
  }
}

export default BaseEntityEndpointProvider;
