/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios';
import { axiosOrgInstance } from '../utils/axios';
import {
  CollectionRequest,
  CollectionFilter,
  DEFAULT_REQUEST_BODY,
  QueryOperator,
} from '../redux/slices/collection.models';
import { CollectionElement } from '../redux/models/database.model';

// This is where the interception happens when you make an api call to your cloud server. The interception is needed because the header with the token needs to be added.
class EntityApiProvider {
  static async getEntityData(url: string, data: CollectionRequest) {
    return axiosOrgInstance({
      method: 'POST',
      url: `${url}/find`,
      data,
      validateStatus: () => true,
    });
  }

  static async getEntityById(url: string) {
    return axiosOrgInstance({
      method: 'GET',
      url,
      validateStatus: () => true,
    });
  }

  static async addEntityData(url: string, body: any) {
    return axiosOrgInstance({
      method: 'POST',
      url,
      data: body,
      validateStatus: () => true,
    });
  }

  static async filterEntityData(url: string, body: any) {
    return axiosOrgInstance.post(`${url}/find`, body);
  }

  static async updateEntity(url: string, body: any) {
    return axiosOrgInstance({
      method: 'PUT',
      url,
      data: body,
      validateStatus: () => true,
    });
  }

  static async uploadFile(url: string, body: any) {
    return axiosOrgInstance.post(`${url}/file/upload`, body);
  }

  static async updateS3Bucket(url: string, body: any) {
    return axios.put(url, body);
  }

  static async downloadFile(url: string, body: any) {
    return axiosOrgInstance.post(`${url}/file/download`, body);
  }

  static async deleteSingleEntityItem(organisationId: string) {
    return axiosOrgInstance.delete(organisationId);
  }

  static parseResponse(response: AxiosResponse<any, any>) {
    switch (response.status) {
      case 200:
        return response.data.data;
      case 403:
        throw new Error("You don't have permissions to view this error");
      case 500:
        throw new Error('Server error');
      default:
        throw new Error('Error loading response');
    }
  }

  static createFilters(query: URLSearchParams, els: CollectionElement[]) {
    const getOperator = (params: URLSearchParams) => {
      const value = params.get('op') || 'and';
      return value === 'and' ? QueryOperator.And : QueryOperator.Or;
    };
    const getFilterValue = (val: string, element: CollectionElement) => {
      if (val === 'null') {
        return null;
      }
      if (element.type === 'number') {
        return parseFloat(val);
      }
      return val;
    };
    const getFilters = (params: URLSearchParams, elements: CollectionElement[]): CollectionRequest => {
      const filters: CollectionFilter[] = elements
        .filter((el) => params.get(el.property))
        .map((el) => {
          const value = params.get(el.property) ?? '';
          const prop = el.type === 'rel' ? `data.${el.rel_filter_prop}` : `data.${el.property}`;
          return {
            value: getFilterValue(value, el),
            field: {
              id: el.name,
              name: prop,
              type: el.type,
            },
            op: 'eq',
          };
        });
      return {
        ...DEFAULT_REQUEST_BODY,
        operator: getOperator(params),
        filters,
      };
    };
    return getFilters(query, els);
  }
}

export default EntityApiProvider;
