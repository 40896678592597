import { IntegrationData } from '../redux/slices/integration.models';
import { axiosInstance } from '../utils/axios';
import BaseEntityApiProvider from './base/BaseEntityApiProvider';

class IntegrationApiProvider extends BaseEntityApiProvider<IntegrationData> {
  constructor() {
    super('integration', axiosInstance);
  }

  authorizeOAuth2(appId: string) {
    const url = `/oauth2/authorize?app_id=${appId}`;
    return this.api.get(url);
  }
}

export default IntegrationApiProvider;
