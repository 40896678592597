/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthState, CollectionData, DatabaseState } from './database.model';
import { GroupsState } from './group.models';
import { OrganisationState } from './organisation.models';
import { DataModel } from '../slices/core.models';
import {
  AUTH,
  AUTOMATIONS,
  AUTOMATION_DETAILS,
  COLLECTIONS,
  DATABASE,
  GROUPS,
  ORGANISATION,
  REPORTS,
} from '../slices/slices';
import { CollectionState } from './collection.models';
import { AutomationDetailsState } from './automation.details.models';
import { AutomationState } from './automation.models';
import { ReportsState } from './report.models';

export interface AppTrigger {
  config: any;
  form_id: string;
  name: string;
  start_page: number;
  trigger_id: string;
  type: string;
}

export interface AppAction {
  action_id: string;
  config: any;
  form_id: string;
  integration: any | null;
  name: string;
  start_page: number;
  type: string;
}

export enum AuthenticationTypes {
  ApiKey = 'api_key',
  Credentials = 'credentials',
  OAuth = 'oauth',
  OAuth2 = 'oauth2',
}

export interface AppData {
  base_url: string;
  name: string;
  logo_url: string;
  collections: CollectionData[];
  triggers?: AppTrigger[];
  actions?: AppAction[];
  authentication_type?: string;
  authentication_types?: AuthenticationTypes[];
}

export type App = DataModel<AppData>;

export interface AppState {
  [AUTH]: AuthState;
  [AUTOMATIONS]: AutomationState;
  [AUTOMATION_DETAILS]: AutomationDetailsState;
  [DATABASE]: DatabaseState;
  [GROUPS]: GroupsState;
  [COLLECTIONS]: CollectionState;
  [ORGANISATION]: OrganisationState;
  [REPORTS]: ReportsState;
}
