import { ApiTokenData } from '../redux/slices/tokens/token.models';
import { axiosInstance } from '../utils/axios';
import BaseEntityApiProvider from './base/BaseEntityApiProvider';

class ApiTokenApiProvider extends BaseEntityApiProvider<ApiTokenData> {
  constructor() {
    super('api-key', axiosInstance);
  }

  permissions(apiKeyId: string) {
    const url = `${this.endpoint.entityPath()}/permissions/${apiKeyId}`;
    return this.api.get(url);
  }
}

export default ApiTokenApiProvider;
