/* eslint-disable @typescript-eslint/no-explicit-any */

export enum SortOrder {
  Ascending = 'ASC',
  Descending = 'DESC',
}

export interface Field {
  id?: string;
  name: string;
  type: string;
}

export interface CollectionFilter {
  field: Field;
  op: string;
  value?: any;
}

interface CollectionPage {
  page: number;
  page_size: number;
}

interface CollectionSorting {
  field: string;
  order: SortOrder;
}

interface CollectionGroup {
  field: string;
}

export enum ElementType {
  String = 'string',
  Rel = 'rel',
}

export enum FilterOp {
  Equals = 'eq',
  NotEquals = 'ne',
  Regex = 'regex',
}

export enum QueryOperator {
  And = 'AND',
  Or = 'OR',
}

export interface CollectionRequest {
  filters: CollectionFilter[];
  operator: string;
  pagination: CollectionPage;
  sorting?: CollectionSorting[];
  grouping?: CollectionGroup;
}

const getSearchField = (field: string, value: string): CollectionFilter => {
  return {
    field: {
      name: `data.${field}`,
      type: ElementType.String,
    },
    op: FilterOp.Regex,
    value,
  };
};

export const getPostSearchBody = (field: string, value: string, secondField?: string) => {
  const primarySearch = getSearchField(field, value);
  const secondSearch = secondField ? getSearchField(secondField, value) : null;
  const filters: CollectionFilter[] = secondSearch ? [primarySearch, secondSearch] : [primarySearch];
  const query: CollectionRequest = {
    filters,
    operator: secondSearch ? QueryOperator.Or : QueryOperator.And,
    pagination: {
      page: 0,
      page_size: 10,
    },
  };
  return query;
};

export const DEFUALT_CREATED_AT_SORTING: CollectionSorting[] = [
  {
    field: 'created_at',
    order: SortOrder.Ascending,
  },
];

export const DEFAULT_REQUEST_PAGINATION: CollectionPage = {
  page: 0,
  page_size: 50,
};

export const DEFAULT_REQUEST_BODY: CollectionRequest = {
  filters: [],
  operator: QueryOperator.And,
  pagination: DEFAULT_REQUEST_PAGINATION,
  sorting: DEFUALT_CREATED_AT_SORTING,
};
