/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import HopperServiceApiProvider from '../../services/hopperServiceApiProvider';
import { EntityContainer } from './core.models';
import { Integration } from './integration.models';
import { INTEGRATIONS } from './slices';
import Api from '../../services/Api';

const SLICE_NAME = INTEGRATIONS;

type Container = EntityContainer<Integration>;

type InitialState = {
  isIntegrationsLoaded: boolean;
  errorOnLoadingData: boolean;
  container: Container;
  list: Array<Integration>;
};

const initialState = {
  isIntegrationsLoaded: false,
  errorOnLoadingData: false,
  container: {},
  list: [],
} as InitialState;

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setIntegrations(state, action) {
      state.container = action.payload;
      const items: Array<Integration> = Object.values(action.payload);
      items.sort((a: any, b: any) => {
        const aName = a?.data?.name || '';
        const bName = b?.data?.name || '';
        return aName.localeCompare(bName);
      });
      state.list = items;
    },
    setIsIntegrationsLoaded(state, action) {
      state.isIntegrationsLoaded = action.payload;
    },
    setErrorOnLoadingData(state, action) {
      state.errorOnLoadingData = action.payload;
    },
  },
});

export const { setIntegrations, setIsIntegrationsLoaded, setErrorOnLoadingData } = slice.actions;

export const loadIntegrations: any = (organisationId: string) => async (dispatch: any) => {
  dispatch(setIsIntegrationsLoaded(false));
  dispatch(setErrorOnLoadingData(false));
  try {
    const { data } = await HopperServiceApiProvider.getIntegrationData(organisationId);
    const integrations: Integration[] = data?.data || [];
    integrations.sort((a: Integration, b: Integration) => {
      const aName = a.data.app?.data?.name || '';
      const bName = b.data.app?.data?.name || '';
      return aName.localeCompare(bName);
    });
    const dataContainer: Container = {};
    integrations.forEach((integration: Integration) => {
      dataContainer[integration.entity_id] = integration;
    });
    dispatch(setIntegrations(dataContainer));
  } catch (error) {
    dispatch(setErrorOnLoadingData(true));
    if (error instanceof Error) {
      const errorMessage = `Couldn't get Integrations. ${error.message}. Please contact support if the problem persists.`;
      console.error(errorMessage);
    }
  } finally {
    dispatch(setIsIntegrationsLoaded(true));
  }
};

export const searchIntegrations = (name: string) => {
  return Api.apps.search('name', name);
};

export const getOAuth2Url = (appId: string) => {
  return Api.integrations.authorizeOAuth2(appId);
};

const integrationsReducer = slice.reducer;
export default integrationsReducer;
export const allState = (state: any) => state[SLICE_NAME];
export const selectIntegrationsList = (state: any) => state[SLICE_NAME].list;
export const isIntegrationsLoaded = (state: any) => state[SLICE_NAME].isIntegrationsLoaded;
export const errorOnLoadingData = (state: any) => state[SLICE_NAME].errorOnLoadingData;
